import clsx from 'clsx';
import { MakeIconMono } from '../../atoms/MakeIconMono';

interface NoImageWrapperProps {
  make?: string;
  className?: string;
  makeIconClassName?: string;
}

export const NoImageWrapper: React.FC<NoImageWrapperProps> = ({
  make = null,
  className = 'm-auto mb-s',
  makeIconClassName,
}) => {
  return (
    <div className="m-auto">
      <div className={className}>
        {make && (
          <MakeIconMono
            make={make}
            className={clsx('m-auto mb-s text-blue-dark', makeIconClassName)}
          />
        )}
        <div className="hidden whitespace-nowrap m:block">
          Image coming soon
        </div>
      </div>
    </div>
  );
};
