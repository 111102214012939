import SearchTemplate, {
  SearchTemplateProps,
  SearchTemplateSeoData,
} from '@/components/template/SearchTemplate/SearchTemplate';
import { getMakesBody } from '@/lib/getMakesBody';
import { getSearchMakesAndModels } from '@/lib/getSearchMakesAndModels';
import {
  getSeoEnrichmentContent,
  getSeoTagParser,
  SeoTagsParserProps,
} from '@/lib/seo/seoTags';
import {
  isSeoSearchResult,
  searchInventoryQuery,
  SearchInventoryQueryResult,
  seoInventoryQuery,
  SeoInventoryQueryResult,
} from '@/lib/staticQueries';
import { BasePageProps } from '@/types/page';
import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getCitiesByState, getTopCities } from '../lib/schema/geo/fetch';
import { City, isCity } from '../lib/schema/geo/types';
import { slugify } from '../lib/utils';

export interface SearchPageProps
  extends Omit<SearchTemplateProps, 'h1Tag' | 'searchType'>,
    Pick<SeoTagsParserProps, 'tagType'> {
  autoCompleteResults: {
    searchMakes: string[];
    searchModels: Record<string, string[]>;
  };
  title: string;
}

export default function Search({
  tagType = 'searchPage',
  showRebates = true,
  title,
  ...rest
}: SearchPageProps) {
  return (
    <main className="flex-grow bg-white">
      <SearchTemplate
        searchType={tagType === 'seoPage' ? 'SEO' : 'Search'}
        showRebates={showRebates}
        h1Tag={title}
        {...rest}
      />
    </main>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const slugArray: string[] =
    typeof context?.params?.slug === 'string'
      ? [context.params.slug]
      : (context?.params?.slug ?? []);

  let searchResults: SearchInventoryQueryResult | SeoInventoryQueryResult;

  const tagType = slugArray[0] === 'search' ? 'searchPage' : 'seoPage';

  if (tagType === 'searchPage') {
    searchResults = await searchInventoryQuery(slugArray.slice(1));
  } else {
    const results = await seoInventoryQuery(context, slugArray);
    searchResults = results;

    /**
     * Construct the canonical url in the form of /{new|old}-cars/{year?}/{make?}/{model?}/{location?}
     * The URL should be all lowercase with no spaces (uses hyphens instead).
     *
     * Note that all canonical URLs have a condition
     */
    let condition: 'New' | 'Used' = 'New';
    if (slugArray[0] === 'new-cars' || slugArray[0] === 'used-cars') {
      condition = slugArray[0] === 'new-cars' ? 'New' : 'Used';
    } else if (results.condition) {
      condition = results.condition;
    } else if (results.years.length) {
      condition =
        Number(results.years[0]) === new Date().getUTCFullYear()
          ? 'New'
          : 'Used';
    }

    results.initialFilters.condition = condition;
    results.condition = condition;

    const conditionSlug = condition === 'New' ? 'new-cars' : 'used-cars';
    const year = results.years.length ? `/${results.years[0]}` : '';
    const make = results.makes?.length ? `/${results.makes[0]}` : '';
    const model = results.models?.length ? `/${results.models[0]}` : '';
    const location = results.location
      ? `/${isCity(results.location.data) ? results.location.data.slug : results.location.data.code}`
      : '';
    const canonicalURL = slugify(
      `/${conditionSlug}${make}${model}${year}${location}`
    );

    // If the current URL is not canonical, redirect to the canonical URL.
    if (`/${slugArray.join('/')}` !== canonicalURL) {
      return { redirect: { permanent: true, destination: canonicalURL } };
    }
  }

  const {
    seoMetaObjTitle,
    seoMetaObjDescription,
    seoMetaObjH1,
    seoMetaKeywords,
  } = getSeoTagParser({
    tagType,
    fuelType: searchResults.initialFilters.fuelType,
    priceEnd: searchResults.initialFilters.price_end,
    ...(isSeoSearchResult(searchResults)
      ? {
          makes: searchResults.makes,
          models: searchResults.models,
          years: searchResults.years,
          bodyTypes: searchResults.bodyTypes,
          locationTitle: searchResults.location?.title,
          condition: searchResults.condition,
          dealer: searchResults.dealer,
        }
      : null),
  });

  let seoData: SearchTemplateSeoData | null = null;
  if (isSeoSearchResult(searchResults)) {
    let citiesInSameState: City[] = [];
    const seoLocation = searchResults.location;
    if (seoLocation) {
      const location = seoLocation.data;
      citiesInSameState =
        (await getCitiesByState(
          isCity(location) ? location.state : location.code
        )) ?? [];
    }

    seoData = {
      enrichmentContent: getSeoEnrichmentContent(searchResults),
      location: searchResults.location ?? null,
      topCities: await getTopCities(),
      citiesInSameState,
    };
  }

  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? 'en', ['common'])),
      initialFilters: searchResults.initialFilters,
      initialSort: searchResults.initialSort,
      showRebates: searchResults.showRebates,
      tagType,
      makesBodyTypes: await getMakesBody(),
      autoCompleteResults: await getSearchMakesAndModels(),
      showNewCheckAvailabilityButton:
        process.env.NEXT_PUBLIC_ENABLE_NEW_CHECK_AVAILABILITY_BUTTON === 'true',
      title: seoMetaObjH1,
      seoData,
      metadata: {
        title: seoMetaObjTitle,
        description: seoMetaObjDescription,
        keywords: seoMetaKeywords,
        noindex: !isSeoSearchResult(searchResults),
      },
    } satisfies BasePageProps<SearchPageProps>,
  };
};
